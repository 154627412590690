<template>
  <div class="videos-content">
    <div
      class="videos-content__bg"
      v-for="file in files"
      :key="file.id"
      @click="openModalPlayer(file)"
    >
      <video>
        <source :src="returnSrcFile(file.path)" type="video/mp4" />
      </video>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.8" cx="20" cy="20" r="20" fill="#343748" />
        <path
          d="M18.8 23.9L23.47 20.4C23.74 20.2 23.74 19.8 23.47 19.6L18.8 16.1C18.47 15.85 18 16.09 18 16.5V23.5C18 23.91 18.47 24.15 18.8 23.9ZM20 10C14.48 10 10 14.48 10 20C10 25.52 14.48 30 20 30C25.52 30 30 25.52 30 20C30 14.48 25.52 10 20 10ZM20 28C15.59 28 12 24.41 12 20C12 15.59 15.59 12 20 12C24.41 12 28 15.59 28 20C28 24.41 24.41 28 20 28Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { domainStorage } from "@/globalVariables";
import { mapActions } from "vuex";
export default {
  name: "VideosContent",
  mixins: [],
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("MessengerModule", ["setVideoPlayerData"]),
    returnSrcFile(src) {
      return domainStorage + "/chat/files/" + src;
    },
    openModalPlayer(item) {
      this.setVideoPlayerData(item);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
@import "~@/assets/base/breakpoints/breakpoints";

.videos-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  margin-bottom: 60px;
  @media screen and (max-width: $xs) {
    margin-bottom: 0;
  }
  &__bg {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    video {
      width: 100%;
      margin-bottom: 8px;
      border-radius: 4px;
    }
    svg {
      position: absolute;
      z-index: 100;
    }
  }
}
</style>
